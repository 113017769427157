import { graphql } from "gatsby";
import React from "react";
import SpacerComponent from "../components/contents/spacer";
import FooterComponent from '../components/footer/footer';
// import Header from '../components/header/header';
import Modules from "../components/modules/modules";

class PageTemplate extends React.Component {

  constructor() {
    super();

  }
  shouldComponentUpdate() {



    const { transitionStatus, entry, exit } = this.props;
    let shouldUpdate = transitionStatus.indexOf('exit') < 0;

    return true;
  }
  render() {

    const { data, transitionStatus } = this.props;
    const page = data.page.data;
    // const tl = document.querySelector('.tl-edges > div');
    console.log('Render page');
    return (

      <div>
        {/* <Header /> */}
        {page.body && page.body.map((data, i) => <Modules key={i} __typename={data.__typename} data={data} transitionStatus={transitionStatus} />
        )}
        <SpacerComponent />
        <FooterComponent />
      </div>
    )
  }
}
export default PageTemplate;





export const query = graphql`
  query($slug: String!, $lang: String!) {
    page: prismicPage(uid: { eq: $slug },lang: { eq: $lang }) {
      uid
        data{
          title {
            text
          }
          body {
            __typename

            ... on PrismicPageBodyHero{
              primary {
                hero_title
                image{
                  ...prismicPageBodyHeroPrimaryImageFragment
                }
              }
            }
            
            ... on PrismicPageBodyTextTwoColumns{
              id
              items{
                text {
                  html
                  text
                }
              }
              primary{
                mobile_stack
              }
            }
            ... on PrismicPageBodyMedia{
              id
              
              items{
                image {
                  ...prismicPageBodyMediaItemsImageFragment
                }
                caption {
                  html
                  text
                }
              }
            }
            ... on PrismicPageBodyProductList{
              
              primary{
                filter_text
                category_text
                case_study_text
              }
            }
            ... on PrismicPageBodyTitle{
              
              primary {
                title1 {
                  html
                  text
                }
                intro
                alignment
              }
            }

            ... on PrismicPageBodySpacer{
              id
              
            }
            ... on PrismicPageBodyDesignersList{
              id
              
            }
          }
          
        }
    }
    
  }
`


PageTemplate.query = query;